/*###############################################

@Title: TK Theme Config
@Description:
This file contains the config settings for the theme. It will overwrite the core vars.

###############################################*/




/*
 
 Colors
 ---------------------------------------------------- */
 
$brand-color-1: #838e17 !default;
$brand-color-2: #838e17 !default;
$brand-color-3: #838e17 !default;
$brand-color-4: #838e17 !default;

$theme-color-1: #838e17 !default;
$theme-color-2: #838e17 !default;


/*
// LIGHT
$chart-color-1: #7FB4AF !default;
$chart-color-2: #B47F88 !default;
$chart-color-3: #E3E19B !default;
$chart-color-4: #8EB7E0 !default;
$chart-color-5: #E3B19B !default;
$chart-color-6: #83D4E1 !default;
$chart-color-7: #BEA2C2 !default;
*/

// DARK
$chart-color-1: #5FA19B !default;
$chart-color-2: #A15F6A !default;
$chart-color-3: #D8D573 !default;
$chart-color-4: #669ED5 !default;
$chart-color-5: #D89273 !default;
$chart-color-6: #5AC6D7 !default;
$chart-color-7: #A883AE !default;


/*
 
 Basics
 ---------------------------------------------------- */

// Max Width and Max Width Paddings
$mw: 100% !default;
$mw-padding: 2rem !default;
// Grid
$_opc-grid-columns: 12 !default;
$_opc-grid-gutter-width: 10px !default;
$_opc-grid-column-width: 120px !default;


// button colors
$primary-button-color: $brand-color-2 !default;
$secondary-button-color: #ccc !default;
$loud-button-color: $brand-color-1 !default;
// state colors
$success-color: #329c5c !default;
$error-color: #e05959 !default;
$info-color: #83b3d3 !default;
$warning-color: #ffcc00 !default;
$progress-color: #ccc !default;


$loud-button-color: $brand-color-1 !default;
$_opc-boolean-active-color:$brand-color-1 !default;
$selected-date-color:$brand-color-1 !default;
$color-greylight: #eee !default;
$color-grey: #aaa !default;
// 
// Tabs 
$_opc-tab-color: #333 !default;
$_opc-tab-padding: 1.5rem 2rem !default;
$_opc-tab-bg: transparent !default;
$_opc-tab-bg-active: #fff !default;
$_opc-tab-bg-hover: rgba(255, 255, 255, 0.7) !default;
$_opc-tab-color-hover: #000 !default;
$_opc-tab-list-bg: transparent !default;


// stock colors
$stock-on-stock: $success-color !default;
$stock-out-stock: $error-color !default;
$stock-call-stock: $info-color !default;
$stock-less-stock: #f0bb54 !default;
$stock-na-stock: $error-color !default;
// breakpoints
$breakpoints: ( 'tiny': 480px, 'small': 640px, 'medium': 800px, 'normal': 1025px, 'large': 1400px, 'xs': 480px, 's': 640px, 'm': 800px, 'l': 1025px, 'xl': 1400px) !default;
// sizes 
$padding: ( 'tiny': 0.5rem, 'small': 0.75rem, 'medium': 1.25rem, 'normal': 1.25rem, 'large': 1.75rem, 'huge': 2rem) !default;

/* !!!! DEPRECATED, JUST FOR COMPATIBLITY. USE PADDING INSTEAD !!!!! */

$SIZES: ( 'tiny': 0.5rem, 'small': 0.75rem, 'medium': 1.25rem, 'normal': 1.25rem, 'large': 1.75rem, 'huge': 2rem) !default;
// heights
$heights: ( 'tiny': 2.6rem, 'small': 3.2rem, 'medium': 4rem, 'normal': 4rem, 'large': 5rem, 'huge': 6rem) !default;
$spacing: ( 'small': 2rem, 'medium': 3rem, 'large': 4rem) !default;

/*
 
 typography
 ---------------------------------------------------- */

// font-size
$_opc-base-font-size: 1.4rem !default;
// fluid typography 
$_opc-fluid-typo: "false" !default;
$_opc-font-size-multiplyer: 1.2 !default;
$_opc-font-min-width: 320px !default;
$_opc-font-max-width: $mw !default;
// font family and styles
$_opc-base-font-family: "Open Sans" !default;
$_opc-base-font-weight: 400 !default;
$_opc-base-line-height: 1.4 !default;
$_opc-headline-font-family: "SegoeUI" !default;
$_opc-headline-font-weight: 200 !default;
$_opc-headline-line-height: 1.25 !default;
$_opc-lead-font-family: "Open Sans" !default;
$_opc-lead-font-weight: 200 !default;
$_opc-lead-line-height: 1.25 !default;
// headlines
$_opc-font-size-h1: 28px !default;
$_opc-font-size-h2: 24px !default;
$_opc-font-size-h3: 20px !default;
$_opc-font-size-h4: 18px !default;
$_opc-font-size-h5: 16px !default;
$_opc-font-size-h6: 14px !default;
$_opc-font-size-h1-max: $_opc-font-size-h1 * $_opc-font-size-multiplyer !default;
$_opc-font-size-h2-max: $_opc-font-size-h2 * $_opc-font-size-multiplyer !default;
$_opc-font-size-h3-max: $_opc-font-size-h3 * $_opc-font-size-multiplyer !default;
$_opc-font-size-h4-max: $_opc-font-size-h4 * $_opc-font-size-multiplyer !default;
$_opc-font-size-h5-max: $_opc-font-size-h5 * $_opc-font-size-multiplyer !default;
$_opc-font-size-h6-max: $_opc-font-size-h6 * $_opc-font-size-multiplyer !default;
// lead and copy text
$_opc-font-size-lead: $_opc-base-font-size * 1.4 !default;
$_opc-font-size-lead-max: $_opc-font-size-lead * $_opc-font-size-multiplyer !default;
$_opc-font-size-small: 1.2rem !default;
$_opc-font-size-small-max: $_opc-font-size-small * $_opc-font-size-multiplyer !default;
$_opc-font-size-copy: $_opc-base-font-size !default;
$_opc-font-size-copy-max: $_opc-font-size-copy * $_opc-font-size-multiplyer !default;
$_opc-font-size-s: 1.2rem !default;
$_opc-font-size-m: 1.4rem !default;
$_opc-font-size-l: 1.6rem !default;
$_opc-font-size-xl: 1.8rem !default;

/*
 
 SVG Icons
 ---------------------------------------------------- */

// make svg icons available in sass as background image
$op-icon-cross-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z "/></svg>' !default;
$op-icon-check-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z "/></svg>' !default;
$op-icon-angle-right-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 12.96875 4.28125 L 11.53125 5.71875 L 21.8125 16 L 11.53125 26.28125 L 12.96875 27.71875 L 23.96875 16.71875 L 24.65625 16 L 23.96875 15.28125 Z "/></svg>' !default;
$op-icon-angle-left-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path d="M19.031 4.281l-11 11-0.688 0.719 0.688 0.719 11 11 1.438-1.438-10.281-10.281 10.281-10.281z"></path></svg>' !default;
$op-icon-angle-down-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z "/></svg>' !default;
$op-icon-stock-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><circle cx="50" cy="50" r="40" /></svg>' !default;

/*
 
 Buttons
 ---------------------------------------------------- */

$_opc-button-base-progress-scale: 0.8 !default;
$_opc-button-base-border-radius: 3px !default;
$_opc-button-primary-link-color: $primary-button-color !default;
$_opc-button-primary-link-color-hover: darken($_opc-button-primary-link-color, 20%) !default;
$_opc-button-secondary-link-color: $secondary-button-color !default;
$_opc-button-secondary-link-color-hover: darken($_opc-button-secondary-link-color, 20%) !default;
$_opc-button-primary-border-radius: $_opc-button-base-border-radius !default;
$_opc-button-primary-color: rgba(#fff, 0.9) !default;
$_opc-button-primary-color-hover: rgba(#fff, 1) !default;
$_opc-button-primary-bg: $primary-button-color !default;
$_opc-button-primary-bg-hover: darken($_opc-button-primary-bg, 5%) !default;
$_opc-button-primary-progress-color: $progress-color !default;
$_opc-button-primary-success-color: $success-color !default;
$_opc-button-secondary-border-radius: $_opc-button-base-border-radius !default;
$_opc-button-secondary-color: rgba(#000, 0.8) !default;
$_opc-button-secondary-color-hover: rgba(#000, 1) !default;
$_opc-button-secondary-bg: $secondary-button-color !default;
$_opc-button-secondary-bg-hover: darken($secondary-button-color, 5%) !default;
$_opc-button-secondary-progress-color: $progress-color !default;
$_opc-button-secondary-success-color: $success-color !default;
$_opc-button-loud-border-radius: $_opc-button-base-border-radius !default;
$_opc-button-loud-color: rgba(#fff, 0.8) !default;
$_opc-button-loud-color-hover: rgba(#fff, 1) !default;
$_opc-button-loud-bg: $loud-button-color !default;
$_opc-button-loud-bg-hover: darken($loud-button-color, 5%) !default;
$_opc-button-loud-progress-color: $progress-color !default;
$_opc-button-loud-success-color: $success-color !default;
$_opc-button-font-size-s: $_opc-font-size-s !default;
$_opc-button-font-size-m: $_opc-font-size-m !default;
$_opc-button-font-size-l: $_opc-font-size-l !default;
$_opc-button-font-size-xl: $_opc-font-size-xl !default;

/*
 
 Forms
 ---------------------------------------------------- */

$_opc-form-field-font-family: sans-serif !default;
$_opc-form-field-font-size: inherit !default;
$_opc-form-field-margin: 2rem !default;
$_opc-form-field-border: 1px solid #ccc !default;
$_opc-form-field-border-hover: 1px solid #bbb !default;
$_opc-form-field-border-focus: 1px solid #aaa !default;
$_opc-form-field-border-invalid: 1px solid $error-color !default;
$_opc-form-field-border-valid: 1px solid $success-color !default;
$_opc-form-field-color: #000 !default;
$_opc-form-field-color-invalid: $error-color !default;
$_opc-form-field-color-message-invalid: $error-color !default;
$_opc-form-field-color-valid: $success-color !default;
$_opc-form-field-color-message-valid: $success-color !default;
$_opc-form-field-placeholder-color: #ccc !default;
$_opc-form-field-bg: #fff !default;
$_opc-form-field-bg-hover: #fff !default;
$_opc-form-field-bg-focus: #fff !default;
$_opc-form-field-opacity-disabled: 1 !default;
$_opc-form-field-label-color: #000 !default;
$_opc-form-field-label-font-weight: 600 !default;
$_opc-form-field-label-font-family: inherit !default;
$_opc-form-field-msg-font-size: 1.2rem !default;
$_opc-form-field-font-size-s: $_opc-font-size-s !default;
$_opc-form-field-font-size-m: $_opc-font-size-m !default;
$_opc-form-field-font-size-l: $_opc-font-size-l !default;
$_opc-form-field-font-size-xl: $_opc-font-size-xl !default;
$_opc-radio-width: 2rem !default;
$_opc-radio-height: 2rem !default;
$_opc-radio-border: 1px solid #ccc !default;
$_opc-radio-border-hover: 1px solid #bbb !default;
$_opc-radio-border-focus: 1px solid #aaa !default;
$_opc-radio-border-checked: 1px solid #ccc !default;
$_opc-radio-border-radius: 2rem !default;
$_opc-radio-color: #000 !default;
$_opc-radio-bg: #fff !default;
$_opc-radio-bg-hover: #fff !default;
$_opc-radio-bg-focus: #fff !default;
$_opc-radio-bg-checked: #fff !default;
$_opc-radio-icon: inline-svg($op-icon-check-svg, '#333') !default;
$_opc-radio-icon-checked: inline-svg($op-icon-check-svg, '#333') !default;
$_opc-radio-icon-size: 1.6rem !default;
$_opc-radio-label-font-size: 1.4rem !default;
$_opc-checkbox-width: 2rem !default;
$_opc-checkbox-height: 2rem !default;
$_opc-checkbox-border: 1px solid #ccc !default;
$_opc-checkbox-border-hover: 1px solid #bbb !default;
$_opc-checkbox-border-focus: 1px solid #aaa !default;
$_opc-checkbox-border-checked: 1px solid #ccc !default;
$_opc-checkbox-border-radius: 0.5rem !default;
$_opc-checkbox-color: #000 !default;
$_opc-checkbox-bg: #fff !default;
$_opc-checkbox-bg-hover: #fff !default;
$_opc-checkbox-bg-focus: #fff !default;
$_opc-checkbox-bg-checked: #fff !default;
$_opc-checkbox-icon: inline-svg($op-icon-check-svg, '#333') !default;
$_opc-checkbox-icon-checked: inline-svg($op-icon-check-svg, '#333') !default;
$_opc-checkbox-icon-size: 1.6rem !default;
$_opc-checkbox-label-font-size: 1.4rem !default;
$_opc-select-icon: inline-svg($op-icon-angle-down-svg, '#999') !default;

/*
 
 Links
 ---------------------------------------------------- */

$_opc-link-color: $brand-color-1 !default;
$_opc-link-color-visited: $_opc-link-color !default;
$_opc-link-color-hover: darken($_opc-link-color, 10%) !default;
$_opc-link-color-active: darken($_opc-link-color, 5%) !default;
$_opc-link-underline-animation-duration: 0.2s !default;

/*
 
 Autocomplete
 ---------------------------------------------------- */

$autocomplete-image-width:90px !default;
$autocomplete-highlight-color: $brand-color-1 !default;

/*
 
 Tooltips
 ---------------------------------------------------- */

$tooltip-bg-color:#fff !default;
$tooltip-font-color: #333 !default;

/*
  
  Verkaufsdokumente Status DocStateCd Farben
  ---------------------------------------------------- */

$saldoc-docstatecd-color-40:$error-color !default;
$saldoc-docstatecd-color-30:$success-color !default;
$saldoc-docstatecd-color-20:black !default;
$saldoc-docstatecd-color-10:black !default;

/*
  
  Rapporte Status DocStateCd Farben
  ---------------------------------------------------- */

$asstdoc-docstatecd-color-40:$error-color !default;
$asstdoc-docstatecd-color-30:$success-color !default;
$asstdoc-docstatecd-color-10:black !default;
/*
  
  Rapportzeilen Zeilenart Farben
  ---------------------------------------------------- */

$asstdoc-asstitemtype-color-n:#888 !default;
$asstdoc-asstitemtype-color-e:#628ef7 !default;
$asstdoc-asstitemtype-color-i:#a96cbd !default;



/*
 
 Filter
 ---------------------------------------------------- */
 $_opc-filter-tag-bg: #D5D5D5 !default;
 $_opc-filter-tag-color: #000 !default;
 
/*
 
 TK Path
 ---------------------------------------------------- */

$tk-path:"./themes/tk-1" !default;