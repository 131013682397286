.opc-navigation {

    &[data-op-navtype="simple"] {
		a {
			&::before {
				font-family:'Font Awesome 5 pro';
				content: "\f111";
				font-size:0.6rem;
				//background-image: inline-svg($op-icon-angle-right-svg, $theme-color-1);
				background:none;
				opacity: 1;
				margin-top: -2px;
				font-weight:bold;
				color:#AAA;
				margin-right: 0.8rem;
			}
			//color: darken($theme-color-1,10%);
		}
        .navigation-level-2 {

            > li  {
				&:hover{
					a{
						background:none;
					}
				}

                > a {
                    text-transform: uppercase !important;
                    font-size: 1.2rem;
                    padding: 2rem 2rem 0.6rem 2rem;
                    color: #555;
					cursor: default;

					&.logout{
						cursor: pointer;
						color: #000;
						pointer-events: all;
					}

                    &::before {
                        display:none;
                    }
                }
            }
        }
        .navigation-level-3 {
            margin-bottom: 0rem;

            li a {
                &:hover {
                    background-color: rgba(#888, 0.1);
					&::before {
						
						color:$theme-color-1;
					}
				}

				&.is-active {
					font-weight: 600;
					&::before {
						color:$theme-color-1;
					}
				}
            }


            > li {
				&.is-active-parent > a{
					font-weight: 600;
				}
                > a {
					padding:0.6rem 2rem;
					display: flex;
					align-items: center;
                }
            }
        }

        .navigation-level-4 {
            margin-bottom: 1rem;

            li a {
                &:hover {
                    background-color: rgba($theme-color-1, 0.1);
                }
            }


            > li {
                > a {
				   padding:0.6rem 0rem 0.6rem 4rem;
                   display: flex;
                   align-items: center;
                }
            }
        }

    }
}