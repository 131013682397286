
/* Styleguide - List */
dl.attribut-description{
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
	margin-bottom: 16px;

	dt,dd{
		border-bottom: solid 1px #e3e5e7;
		padding: 3px 0;	

		&:last-of-type{
			border-bottom: none; 
		}
	}

	dt{
		width: 20%;
	}

	dd{
		width: 80%;

		&:first-of-type{
			font-weight:bold;
		}

		ul{
			list-style: none;
			padding-left: 0; 
			margin-top:0;
			li {
		  		padding: 0;
				  border-bottom: solid 1px #e3e5e7; 
			}
		}
	}
}