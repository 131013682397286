/*Projektspezifische Styles: hier!*/

body.extern {
  .content {
    left: 0;
    padding: 0;
  }
  .header-actions {
    display: none !important;
  }
}


/* ---------- Warehouse fuer Chauffeuer Bereich ---------- */

.color-whbereich-10 {
  background-color: #90C9F0;
}

.color-whbereich-15 {
  background-color: #F29191;
}

.color-whbereich-20 {
  background-color: #F2DE79;
}

.color-whbereich-30 {
  background-color: #A1F291;
}

.color-whbereich-40 {
  background-color: #F29191;
}

.text-center {
  text-align: center;
}

#chauffeurBelege td,
#chauffeurBelege th {
  font-size: 14px
}  

.tour-40 {
  background-color: #F6CECE !important;
}