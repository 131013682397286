/*###############################################

@Title: Grid
@Description:
Custm Grid patterns

###############################################*/

.opc-row{
	
    align-content: flex-start;

    align-items: stretch;
	.opc-col {
		margin-bottom: $_opc-grid-gutter-width * 2;

		&.col-flex-end{
			align-self:flex-end;
		}
	}

	&[data-op-style="info"]{
		[data-op-col="12-6-4"], [data-op-col="12-6-3"], [data-op-col="12-6-2"] {
			@include mq($min-width: map-get($breakpoints, l)) {
				margin-bottom: 0px;
			}
		}
	}
}

[data-op-col="12-4-2"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(4);
        max-width: span(4);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: span(2);
        max-width: span(2);
    }
}

[data-op-col="12-4-4"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(4);
        max-width: span(4);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: span(4);
        max-width: span(4);
    }
}

[data-op-col="12-6-2"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(6);
        max-width: span(6);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: span(2);
        max-width: span(2);
    }
}

[data-op-col="12-6-3"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(6);
        max-width: span(6);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: span(3);
        max-width: span(3);
    }
}

[data-op-col="12-6-4"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(6);
        max-width: span(6);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: span(4);
        max-width: span(4);
    }
}

[data-op-col="12-6-6"] {
    width: 100%;
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(6);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(6);
    }
}

[data-op-col="12-6-8"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(6);
        max-width: span(6);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: span(8);
        max-width: span(8);
    }
}

[data-op-col="12-6-9"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(6);
        max-width: span(6);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: span(9);
        max-width: span(9);
    }
}

[data-op-col="12-8-8"] {
	@include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
		width: span(8);
		max-width: span(8);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
		width: span(8);
		max-width: span(8);
    }
}

[data-op-col="12-12-2"] {
	@include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
		width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
		width: span(2);
		max-width: span(2);
    }
}

[data-op-col="12-12-10"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
		width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
		width: span(10);
		max-width: span(10);
    }
}

[data-op-col="12-12-4"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
		width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
		width: span(4);
		max-width: span(4);
    }
}

[data-op-col="12-12-6"] {
	@include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
		width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
		width: span(6);
		max-width: span(6);
    }
}

[data-op-col="12-12-8"] {
	@include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
		width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
		width: span(8);
		max-width: span(8);
    }
}

[data-op-col="12"],[data-op-col="12-12"],[data-op-col="12-12-12"] {
        width: span(12);
        max-width: span(12);
}