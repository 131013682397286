/*###############################################

@Title: Stacked Layer
@Description:


###############################################*/

// namespace vars
$_footer-bg: #494949; // background of footer
$_footer-inner-bottom-bg: $_footer-bg; // background of bottom part of the footer
$_footer-padding: 4rem; // padding of the footer
$_footer-inner-bottom-padding : 6rem; // padding of the bottom part of the footer
$_footer-color: rgba(#fff, 0.6);
$_footer-color-hover: rgba(#fff, 0.8);
.stacked-layer {
    position: fixed;
    top: 5rem;
    right: 0;
    bottom: 0;
    width: calc(100%);
    background: #eee;
    z-index: 5;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    transition: all 0.4s ease-in-out;
    @include mq($max-width: map-get($breakpoints, s)) {
        top: 6rem;
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: calc(100% - 6rem);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: calc(100% - 33rem);
    }
    &.is-open {
        transform: translateX(0);
        box-shadow: 0 0 10px rgba(#000, 0.4);
    }
    .stacked-layer-head {
        padding: 2rem 2rem 2rem 5rem;
		@include mq($max-width: map-get($breakpoints, s)) {
			padding: 1rem 1rem 1rem 4rem;

		}
        position: relative;
        h1,
        h2 {
            margin-bottom: 0;
        }
        .close-layer-btn {
            display: block;
            position: absolute;
            left: 1rem;
			@include mq($max-width: map-get($breakpoints, s)) {
				left: 0rem;
			}
            top: 50%;
            margin-top: -2rem;
            height: 4rem;
            width: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2.6rem;
            cursor: pointer;
			&:hover{
				color:$brand-color-3;
			}
        }
        .view-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
			@include mq($max-width: map-get($breakpoints, s)) {
				font-size: 22px;
			}
        }
    }
    .stacked-layer-body {
        padding: 0 2rem 2rem;
        flex-grow: 1;
        overflow: auto;
		@include mq($max-width: map-get($breakpoints, s)) {
			padding: 0 1rem 1rem;
		}
    }
    .stacked-layer-footer {
        padding: 1rem 2rem;
		
    }
    .stacked-layer {
        top: 0;
        @include mq($min-width: map-get($breakpoints, medium)) {
            width: calc(100% - 4rem);
        }
        @include mq($min-width: map-get($breakpoints, large)) {
            width: calc(100% - 6rem);
        }
    }
    &.is-panel {
        > .stacked-layer-body {
            padding-bottom: 0px;
            .panel:not(.panel-beside) {
                min-height: 100%;
                border-bottom: none;
            }
            .opc-tabs+.panel {
                min-height: calc(100% - 5rem + 2px);
            }
        }
        .stacked-layer-footer {
            margin: 0rem 2rem 2rem;
            padding: 2rem 2rem;
            border: none;
            background-color: #fff;
            .view-footer {
				margin-bottom: 0;
			}
			@include mq($max-width: map-get($breakpoints, s)) {
				padding: 1rem;
				margin: 0rem 1rem 1rem;
	
			}
        }
    }
}

.view[data-op-view]:hover {
	cursor: pointer;
	background-color: #eee;
}