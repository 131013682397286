fieldset {
    padding: 1rem;
    border: 1px solid #ccc;
    margin: 0;

    legend {
        margin-left: -1rem;
        padding: 0 1rem;
        background: #fff;
        @extend .h3;
    }
}