.mh-search-list.opc-item-list .list-item-column {
    &:nth-child(4) {
        flex: 2;
      }    
    flex: 3;
    &:nth-child(5),
    &:nth-child(6), {
        flex: 1;
      }    
    &:nth-child(7) {
      flex: 6;
    }
  }


.mh-personen.opc-item-list .list-item-column {
  flex: 3;
  &:nth-child(5) {
    flex: 2;
  }
}

.zefix-hinweis {
  color: #aaa;
  font-size: 1rem;
}

.mh-rating {
  padding: 2px 10px;
  &.mh-rating-0 {
	background-color: $color-greylight;
  }
  &.mh-rating-10 {
    background-color: $success-color;
    color: white;
  }
  &.mh-rating-20 {
    background-color: $warning-color;
  }
  &.mh-rating-30 {
    background-color: $error-color;
    color: white;
  }    
}

