.responsive-table {
    overflow-x: auto;
}

table {

    
    &.clickable {
        tr {
            cursor: pointer;
        }
    }

    &.fancy {
        table-layout: unset !important;


        thead {
            tr {
                th,
                td {
                    white-space: nowrap;
                }
            }
        }

        tbody {
            tr {
                border: none;
                background: rgba(#fff, 0.6);
                margin-bottom: 1px;
                border-bottom: 1px solid #eee;
                color: #000;

                td {
                    opacity: 1 !important;
                    white-space: nowrap;

                    svg, i.fa {
                        color: #000;
                    }
                }

                &:hover {
                    background: rgba(#fff, 1);
                    background: rgba($theme-color-1, 0.2);
                    background: rgba(#eee, 1);
                    color: #000;

                   
                }
            }
        }


        



    }



}