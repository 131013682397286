    /* Ergaenzungen Datepicker */
    
    .opc-time .daterangepicker-input+.daterangepicker-calendar.open {
		min-width:auto;
		max-width:250px;
	}
    .opc-date .daterangepicker-input+.daterangepicker-calendar.open {
        max-width: 400px;
	}
	.opc-date,.opc-time{
		&::after{
			color: #aaa;
			font-family: 'Font Awesome 5 pro';
			position: absolute;
			right: 10px;
			top: 10px;
			pointer-events:none;
			-webkit-animation: none;
			-moz-animation: none;
			-ms-animation: none;
			-o-animation: none;
			animation: none;
		}
	}
    .opc-time {
		&::after{
			content: "\f017";
		}
	}
    .opc-date {
        &::after{
			content: "\f133";
		}
    }
    
    .opc-time .daterangepicker-input+.daterangepicker-calendar.open,
    .opc-date .daterangepicker-input+.daterangepicker-calendar.open,
    .daterangepicker-calendar {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
		top: calc(4rem - 1px);
    }
    
    .daterangepicker-calendar.inline {
        top: 1px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    
    .form-field-element {
        .daterangepicker-calendar {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
			border: 1px solid #bbb;
        }
    }
    
    .filter-body .filter-body-search .daterangepicker-months input {
        padding: 0;
        &.cur-year {
            padding: 0 0 0 0.5ch;
        }
    }

	.daterangepicker-mobile{
		width:100%;
	}
	input[type="time"]::-webkit-calendar-picker-indicator { 
		background: none; 
		display:none; 
	}