//Allgemein
//**************************
// Colors for data series and points.
//$colors: #7cb5ec #434348 #90ed7d #f7a35c #8085e9 #f15c80 #e4d354 #2b908f #f45b5b #91e8e1 !default;
//$colors: #7cb5ec #434348 #e4d354 #90ed7d #f7a35c #8085e9 #f15c80 #2b908f #f45b5b #91e8e1 !default;
// Chart background, point stroke for markers and columns etc
$background-color: transparent !default;
// Neutral colors, grayscale by default. The default colors are defined by mixing the background-color with neutral, with a weight corresponding to the number in the name.
$neutral-color-100: #000000 !default; // Strong text.
$neutral-color-80: #333333 !default; // Main text and some strokes.
$neutral-color-60: #666666 !default; // Axis labels, axis title, connector fallback.
$neutral-color-40: #999999 !default; // Credits text, export menu stroke.
$neutral-color-20: #cccccc !default; // Disabled texts, button strokes, crosshair etc.
$neutral-color-10: #e6e6e6 !default; // Grid lines etc.
$neutral-color-5: #f2f2f2 !default; // Minor grid lines etc.
$neutral-color-3: #f7f7f7 !default; // Tooltip backgroud, button fills, map null points.
$highlight-color-100: $brand-color-1 !default; // Drilldown clickable labels, color axis max color.
$highlight-color-80: lighten($highlight-color-100, 20) !default; // Selection marker, menu hover, button hover, chart border, navigator series.
$highlight-color-60: lighten($highlight-color-100, 40) !default; // Navigator mask fill.
$highlight-color-20: lighten($highlight-color-100, 80) !default; // Ticks and axis line.
$highlight-color-10: lighten($highlight-color-100, 90) !default; // Pressed button, color axis min color.
// Fonts
$font-family: inherit !default;
$title-font-size: 1.5em !default;
$subtitle-font-size: 1em !default;
$legend-font-size: 1em !default;
$axis-labels-font-size: 0.9em !default;
// Tooltip
$tooltip-border: 1px !default;
$tooltip-background: $neutral-color-3 !default;
// Axes
$xaxis-grid-line: 0px !default;
// Range-selector
$range-selector-button-border: 0px !default;
$range-selector-input-text: $neutral-color-80 !default;
$range-selector-input-border: $neutral-color-20 !default;
// Data-labels
$data-label-color: $neutral-color-80 !default;
// Buttons
$context-button-background: $background-color !default;
$highcharts-button-background: $neutral-color-3 !default;
$highcharts-button-border: $neutral-color-20 !default;
$highcharts-button-text: $neutral-color-80 !default;
$highcharts-button-pressed-background: $highlight-color-10 !default;
$highcharts-button-pressed-border: $neutral-color-20 !default;
$highcharts-button-pressed-text: $neutral-color-80 !default;
$highcharts-button-hover-background: $neutral-color-10 !default;
$highcharts-button-hover-border: $neutral-color-20 !default;
$highcharts-button-hover-text: $neutral-color-80 !default;
// Navigator
$navigator-series-fill: $highlight-color-80 !default;
$navigator-series-border: $highlight-color-80 !default;
// Scrollbar
$scrollbar-track-background: $neutral-color-5 !default;
$scrollbar-track-border: $neutral-color-5 !default;
// Indicators
$indicator-positive-line: #06B535;
$indicator-negative-line: #F21313;
//Standard CSS Highcharts
//
@import "themes/tk-1/lib/Highcharts-8.1.0/code/css/highcharts.scss";
//
//Organisation Chart
//**************************
.highcharts-organization-series {
    .highcharts-link {
        stroke-width: 3px;
    }
    .highcharts-null-point {
        fill: none;
    }
}

#organization-highcharts-container {
	overflow: visible !important;
    h4 {
        font-size: 1.4rem;
        font-family: "Open Sans";
        font-weight: 400;
        line-height: 1.4;
        a {
            color: #000;
        }
    }
	svg.highcharts-root{
		pointer-events: inherit;
		svg{
			pointer-events: inherit;
		}
	}
	.highcharts-scrolling svg{
		pointer-events: inherit;
	}
}

figure.highcharts-figure {
    width: 100%;
}

.highcharts-tooltip table {
    width: auto;
    tr {
        border: none;
        td {
            padding: 0.2rem;
        }
    }
    .dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
    }
}

.highcharts-axis-labels {
    z-index: -1;
}