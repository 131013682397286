/*###############################################

@Title: Theme 
@Description:
This file contains all theme relevant files

###############################################*/

// import theme specific node modules or external stuff
@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,800";
//@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
// Import 3rd Plugin
@import "./webportal/config/layout/themes/was-6x/lib/tooltip/opacc.lib.tooltip.scss";
// import theme overwrites
@import "atoms/atoms";
@import "organisms/organisms";
@import "other/other.scss";
// import theme strcutures
@import "structure/structure";
// page specific styles
@import "pages/pages";

// Font Awesome
$fa-font-path: "#{$tk-path}/lib/fontawesome-pro-5.15.2-web/webfonts";
@import "../lib/fontawesome-pro-5.15.2-web/scss/fontawesome.scss";
@import "../lib/fontawesome-pro-5.15.2-web/scss/solid.scss";
@import "../lib/fontawesome-pro-5.15.2-web/scss/light.scss";
@import "../lib/fontawesome-pro-5.15.2-web/scss/regular.scss";

// Simplebar
@import "../lib/simplebar/simplebar.scss";

* {
    box-sizing: border-box;
}