/* 	Contact-0010.oc
*/
.contact-0010.opc-item-list .list-item-column {
    flex: 2;

	&:nth-child(8) {
		flex: 3;
	}

	&:nth-child(1),
	&:nth-child(9) {
		flex: 1;
	}
}


/* 	Contact-0015.oc
*/
.contact-0015.opc-item-list .list-item-column {
    flex: 3;

	&:nth-child(4),
	&:nth-child(5) {
		flex: 2;
	}

	&:nth-child(1),
	&:nth-child(7),
	&:nth-child(8) {
		flex: 1;
	}
}


/* 	Contact-0040.oc
*/
.contact-0040.opc-item-list .list-item-column {
	flex: 3;

	&:nth-child(1),
	&:nth-child(3),
	&:nth-child(4) {
		flex: 1;
	}

	&:nth-child(4) {
		text-align: right;
	}
}


/* 	Contact-0050.oc
*/
.contact-0050.opc-item-list .list-item-column {
	flex: 3;

	&:nth-child(1),
	&:nth-child(4) {
		flex: 1;
	}
}


/* 	Contact-0100.oc
*/
.contact-0100.opc-item-list .list-item-column {
	&:nth-child(3) {
		flex: 2;
	}
}


/* Addr-Search-0020
*/
.contact-search .panel {
    margin-top: 2rem;
}