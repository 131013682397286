/*###############################################

@Title: Tabs
@Description:
This file contains the tabs styles

###############################################*/

.opc-tabs .tab-list li.is-active,
.opc-tabs .tab-list .tab-list-item.is-active {
    z-index: inherit;
    border-color: transparent;
}

.opc-tabs {
	overflow-y: hidden;
    overflow-x: auto;
    .tab-list {
        margin-bottom: -1px;
        border: none;
		flex-flow: row nowrap;
		width: 100%;
		overflow-y: visible;
		overflow-x: visible;
		@include mq($min-width: map-get($breakpoints, m)) {
			flex-flow: row wrap;
        }
        &::before,
        &::after {
            display: none;
        }
        li,
        .tab-list-item {
            background: $_opc-tab-list-bg;
            a {
                cursor: pointer;
				white-space: nowrap;
            }
        }
    }
    +.opc-button-wrapper {
        margin-top: 1em;
    }
}

.panel {
    .panel-body {
        .tab-list {
            border-bottom: $_opc-tab-border-thickness solid $_opc-tab-border-color;
            li.is-active,
            .tab-list-item.is-active {
                border-color: $_opc-tab-border-color;
            }
        }
    }
}