// namespace vars
$section-padding: 4rem;
// section layout styles




.section-wrapper {
	@extend %mw;
	padding-top: $section-padding;
	padding-bottom: $section-padding;
    
    &.no-padding {
        padding-top: 0;
        padding-bottom: 0;
    }
    
	.section-inner {
		@extend %mw-inner;
		@include clearfix();
		
        
        // used for aside with content block
		.section-inner-row {
            
			@include mq($min-width: map-get($breakpoints, medium)) {
				@include break;
				@include susy-clearfix;
				margin-left: -$_opc-grid-gutter-width;
				margin-right: -$_opc-grid-gutter-width;
			}
        
            .section-inner-aside {
                width: 100%;
                margin-bottom: 2rem;
                @include mq($min-width: map-get($breakpoints, medium)) {
                    margin-bottom: 0;
                    @include span(3);
                }
            }
            .section-inner-content {
                width: 100%;
                @include mq($min-width: map-get($breakpoints, medium)) {
                    @include span(9);
                }   
            }
		}
		
		.section-inner-content {
			
		}
	}
}

