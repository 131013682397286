.addr-search-list, .addr-search-history {
    .item-list-head {
        .list-item-column[data-sort-type] {
            opacity: 0.5;
            cursor: pointer;
        }
        .list-item-column.sorted {
            opacity: 1;
        }
    }
    .panel-body {
        /*overflow: hidden;*/
        .list-item-column {
            flex: 3;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4) {
                flex: 1;
            }
            &:nth-child(7) {
                flex: 2;
                text-align: right;
            }
        }
    }
}

.addr-duplicate-check{
	.panel-body {
        /*overflow: hidden;*/
        .list-item-column {
            flex: 3;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4) {
                flex: 1;
            }
			&:nth-child(6),&:nth-child(7),&:nth-child(10) {
                flex: 2;
			}
            &:nth-child(10) {
                text-align: right;
            }
        }
    }
}

/* 	Addr-Search-0021.oc
*/
.addr-search-0021.opc-item-list .list-item-column {
	&:nth-child(1),
	&:nth-child(2) {
		flex: 1;
	}
	&:nth-child(3) {
		flex: 3;
	}
}