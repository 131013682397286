.opc-row .opc-col .panel {
	min-height: 100%;
}

/* Lade-Icons Asynchron Viewportloader*/
.icon-loading-placeholder{
	font-size:8rem;
	position: relative;
    left: calc(50% - 4rem);
    top: calc(50% - 6rem);
	margin:3rem 0;
	opacity:0.5;
}

/*Customizable Dashboards*/

// Ansicht
#dashboard-customizable{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
	width: 100%;
	.widget{
		background-color:#fff;
		background:#fff;
		&.panel{
			min-height: 100%;
			min-height:46rem;
			.panel-body{
				max-height: 40rem;
				overflow-y: auto;
    			overflow-x: auto;
			}
		}
	}
}

//Mobile
@include mq($max-width: map-get($breakpoints, l)) {
	#dashboard-settings-btn{
		display:none;
	}
	#dashboard-customizable{
		display:flex;
		align-content: flex-start;
		align-items: stretch;
		clear: both;
		flex-wrap: wrap;
		grid-template-columns: unset;
		grid-gap: unset;
		.widget{
			position: relative;
			margin-left: 5px;
			margin-right: 5px;
			margin-bottom: 10px;
			grid-row: unset !important;
    		grid-column-start: unset !important;
    		grid-column-end: unset !important;
//	Falls Breite <= 6: 50% Breite
//	Falls Breite >6: 100%

			&[data-width="6"],&[data-width="5"],&[data-width="4"],&[data-width="3"],&[data-width="2"],&[data-width="1"]{
				width: calc(50% - 10px);
    			max-width: calc(50% - 10px);
			}
			&[data-width="12"],&[data-width="11"],&[data-width="10"],&[data-width="9"],&[data-width="8"],&[data-width="7"]{
				width:calc(100% - 10px);
				max-width: calc(100% - 10px);
			}
		}

	}
}

@include mq($max-width: map-get($breakpoints, s)) {
	#dashboard-customizable .widget{
		&[data-width="12"],&[data-width="11"],&[data-width="10"],&[data-width="9"],&[data-width="8"],&[data-width="7"],&[data-width="6"],&[data-width="5"],&[data-width="4"],&[data-width="3"],&[data-width="2"],&[data-width="1"]{
			width:calc(100% - 10px);
			max-width: calc(100% - 10px);
		}
	}
}


// Bearbeitungs-Modus
#dashboard-designer{
	.grid-stack { 
		background: transparent;
		margin-left: -10px;
		margin-right: -10px; 
		background-image: linear-gradient(#d5d5d5 1px, transparent 1px),linear-gradient(90deg, #d5d5d5 1px, transparent 0px),linear-gradient(transparent 5px, transparent 100px),linear-gradient(90deg, transparent 5px, transparent 100px);
		background-size: 1px calc(46rem + 20px),8.33% 1px,20px 20px,20px 20px;
		background-position: -2px 0px, 0px -2px, -1px -1px, -1px -1px;
		.grid-stack-placeholder>.placeholder-content{
			border:2px dashed #838e17
		}
		.panel *{ 
			filter: grayscale(1);
			pointer-events:none;
		}
		.panel .panel-body{ 
			max-height: 40rem;
			overflow-y: auto;
			overflow-x: auto;
		}
		>.grid-stack-item>.grid-stack-item-content { 
			overflow-x: hidden;
			overflow-y: hidden;
			background-color: #fff;
			padding:0;
			.panel.widget{
				opacity: 0.9;
				cursor:move;
	
			}
		}
	}


	.opc-button.gridstack-removebtn{
		position:absolute;
		right:1rem;
		top:1rem;
		z-index:2;
		width:4rem;
	}
	.opc-button.gridstack-settingsbtn{
		position:absolute;
		right:6rem;
		top:1rem;
		z-index:2;
		width:4rem;
	}
	#savedata{
		width:100%;
		height:60rem;
	}
}

.content[data-lc="Dashboard-10"]{
	.page-heading>div{
		justify-content: space-between;
    	width: 100%;
		.dashboard-title{
			margin-bottom:0;
			margin-left:3rem;
			input{
				min-width:30rem;
				font-size: 110%;
				font-weight:bold;
			}
		}
	}
	
}

#dashboard-settings{
	.opc-item-list{
		margin:0;
		.list-item {
			.list-item-column:nth-child(1){
				padding-left:3rem;
				position:relative;
				>svg{
					position:absolute;
					left: 1rem;
					top: 0.7rem;
				}
			}
		}
	}
}


#add-widget-list{
	z-index:101;
	.opc-item-list .list-item-column{
		flex: 2;
		vertical-align: top;
		align-self: flex-start;
		&:nth-child(2){
			strong{
				display:block;
				margin-top:0.5rem;
				margin-bottom:1rem;
			}
		}
		&:nth-child(3){
			flex: 1;
			text-align: right;
			button{
				margin-top: 1.7rem;
			}
		}
	 	.image{
			text-align: left;
			img{
				width: 95%;
				max-width: 700px;
				max-height: unset;
				vertical-align: top;
				border:0.5rem solid #efefef;
			}
		}
	}
}

#dashboardconfigform,#optionssaveform{
	display:none;
}

#optionsform {
	
	.dashboard-options{
		.filter-tag-list .tag {
			padding: 0.6rem 3.3rem 0.6rem 1.5rem;
		}
	}
}
#optionsform .dashboard-options{
	.filter-tag-field{
		.opc-form-field{
			margin-bottom:0;
		}
		.filter-tag-list{
			margin-top:1rem;
			margin-bottom:2rem;
		}
	}
}

.dashboard-json-codeblock{
	background:#333;
	color:#d1e428;
	height:27rem;
	padding:0.5rem;
	display: block;
    overflow: auto;
    white-space: nowrap;
	margin-right:2rem;
}