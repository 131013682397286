/*###############################################

@Title: Lists
@Description:
This file contains the list styles

###############################################*/

dl {
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
    dt {
        font-weight: bold;
    }
    dd {
        margin: 0;
        padding: 0;
    }
    &[data-op-style="left"] {
        display: flex;
        flex-wrap: wrap;
        dt {
            flex: 1;
            flex-basis: 25%;
        }
        dd {
            flex: 3;
            flex-basis: 75%;
        }
    }
}