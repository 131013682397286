/*
	Rapporte DocStateCd Icons
*/
[data-op-item-marker-value^="AsstDoc-FilterAsstDoc_DocDate"],[data-op-item-marker-value^="AsstDocUser-AsstDoc_DocStateCd"]{
    &:before {
		content: "\f15c";
    }
}

[data-op-item-marker-value="AsstDoc-AsstDoc_DocStateCd-10"],[data-op-item-marker-value="AsstDocUser-AsstDoc_DocStateCd-10"] {
    color: $asstdoc-docstatecd-color-10;
    &:before {
        font-weight: lighter;
    }
}
[data-op-item-marker-value="AsstDoc-AsstDoc_DocStateCd-30"],[data-op-item-marker-value="AsstDocUser-AsstDoc_DocStateCd-30"] {
	color: $asstdoc-docstatecd-color-30;
	&:before {
		font-weight: 900;
	}
}
[data-op-item-marker-value="AsstDoc-AsstDoc_DocStateCd-40"],[data-op-item-marker-value="AsstDocUser-AsstDoc_DocStateCd-40"] {
    color: $asstdoc-docstatecd-color-40;
    &:before {
		font-weight: 900;
    }
}


/* 
	Zeilenstatus Icons
*/
[data-op-item-marker-value*="-AsstDocItem_AsstStateCd"]{
    &:before {
		content: "\f0c8";
    }
}

[data-op-item-marker-value$="-AsstDocItem_AsstStateCd-10"]{
	color: $asstdoc-docstatecd-color-10;
	&:before {
		font-weight: lighter;
	}
}
[data-op-item-marker-value$="-AsstDocItem_AsstStateCd-30"]{
	color: $asstdoc-docstatecd-color-30;
    &:before {
		font-weight: 900;
    }
}
[data-op-item-marker-value$="-AsstDocItem_AsstStateCd-40"]{
	color: $asstdoc-docstatecd-color-40;
	&:before {
		font-weight: 900;
	}
}

/* 
	Zeilenart Icons
*/
[data-op-item-marker-value*="-AsstDocItem_AsstItemTypeCd"]{
    &:before {
		content: "\f45c";
		font-weight: 900;
		
    }
}
[data-op-item-marker-value$="-AsstDocItem_AsstItemTypeCd-A"]{
	display:none;
	&:before {
		display:none;
	}
}
[data-op-item-marker-value$="-AsstDocItem_AsstItemTypeCd-N"]{
	color: $asstdoc-asstitemtype-color-n;
	
}
[data-op-item-marker-value$="-AsstDocItem_AsstItemTypeCd-E"]{
	color: $asstdoc-asstitemtype-color-e;
    
}

// Status
.asststatecd-30{
	color:$asstdoc-docstatecd-color-30;
}
.asststatecd-40{
	color:$asstdoc-docstatecd-color-40;
}

/* 	AsstDoc-0010.oc
*/
.asstdoc-0010.opc-item-list .list-item-column {
	flex: 1;
	
}

/* 	AsstDoc-0015.oc
*/
.asstdoc-0015.opc-item-list .list-item-column {
	flex: 1;
	&:nth-child(3){
		flex: 2;
	}
}


/* 	
	Icons AsstItemType
*/
.opc-item-list{

	.list-item[data-op-asstitemtypecd="A"]{
		border-top:1px solid #ddd;
		.list-item-column {
			font-weight:bold;
		}
	}

	.list-item-column {
		//Icon
		svg{
			&.asstitemtype-n{
				color:$asstdoc-asstitemtype-color-n;
			}
			&.asstitemtype-e{
				color:$asstdoc-asstitemtype-color-e;
				margin-left:1.3rem;
			}
			&.asstitemtype-i{
				color:$asstdoc-asstitemtype-color-i;
			}
		}
		&:nth-child(1){
			white-space: nowrap;
		}
	}

}


/* 	AsstDoc-0030.oc
*/
.asstdoc-0030.opc-item-list{

	.list-item-column {

		flex: 3;
		&:nth-child(1),&:nth-child(2),&:nth-child(4),&:nth-child(5){
			flex: 2;
		}
		
		&:nth-child(6),&:nth-child(8),&:nth-child(9){
			flex: 1;
		}
		&:nth-child(8),&:nth-child(9){
			text-align:right;
		}
	} 
}


/* 	AsstDoc-0050.oc
*/
.asstdoc-0050 .opc-col .view{
	padding:1rem;
	border:1px solid #eee;
	height:100%;
}

/* 	
	Spalten
*/
.asstdoc-0100{
	&.opc-item-list {
		.list-item-column {
			flex: 3;
			
			&:nth-child(5),&:nth-child(8),&:nth-child(9){
				flex: 1;
			}
		}
	}
}

.asstdoc-0101,
.asstdoc-0102{
	&.opc-item-list {
		.list-item-column {
			flex: 3;
			&:nth-child(6),&:nth-child(8),&:nth-child(9){
				flex: 1;
			}
			svg{
				&.asstitemtype-e{
					margin-left:0;
				}
			}
		}
	}
}

/* 	
	AsstDoc-0200.oc - Mitarbeiterrapport
*/
.asstdoc-0200{
	&.opc-item-list {
		.list-item-column {
			flex: 3;
			&:nth-child(2){
				white-space: nowrap;
			}
			&:nth-child(2),&:nth-child(6){
				flex: 1;
			}
			&:nth-child(8),&:nth-child(9){
				flex: 1;
				text-align: right;
			}
			svg{
				&.asstitemtype-e{
					margin-left:0;
				}
			}
		}
		.list-item.total-month,.list-item.total-week,.list-item.total-day{
			font-weight:bold;
			.list-item-column {
				flex: 3;
				&:nth-child(1){
					flex: 17;
				}
				&:nth-child(2),&:nth-child(3){
					flex: 1;
					text-align: right;
				}
			}
		}
		.list-item.total-week{
			border-top:1px solid #ddd;
		}
	}
}
.opc-button-wrapper-asstdoc-0200 {

	span{
		margin: 0.1em 0.5em;
		font-size: 1.5em;
	}
	.opc-form-field{
		margin-right:2rem;
	}
}


/* 	
	AsstDoc-0250.oc - History
*/
.asstdoc-0250 {
	&.opc-item-list {
		.list-item-column {
			flex: 3;
			&:nth-child(1),&:nth-child(7){
				flex: 1;
			}
			&:nth-child(9),&:nth-child(10){
				flex: 1;
				text-align: right;
			}
			svg{
				&.asstitemtype-e{
					margin-left:0;
				}
			}
		}
	}
}




/* 	AsstDoc-0100.oc / AsstDoc-0101.oc / AsstDoc-0102.oc
	Fixierung Drill-Down-Werte
*/
.asstdoc-0100,
.asstdoc-0101,
.asstdoc-0102{
	&.opc-filter-set.asstdoc-drilldown {
		.opc-filter[id$="-AsstDoc_InternalNo"],
		.opc-filter[id$="-AsstDocItem_AssignNo"],
		.opc-filter[id$="-AsstDocItem_ItemNo"]{
			display:none;
		}

		.filter-tag-list{
			.tag-remove[data-op-filterid$="-AsstDoc_InternalNo"],
			.tag-remove[data-op-filterid$="-AsstDocItem_AssignNo"],
			.tag-remove[data-op-filterid$="-AsstDocItem_ItemNo"]{
				display:none;
			}
		}
	}
}

/* AsstDoc-0110.oc
*/
form[data-ecrm-artservtypecd].asstdoc-0110{
	.opc-form-field[data-ecrm-artservtypecd], div.opc-row[data-ecrm-artservtypecd], .opc-form-field[data-ecrm-artispseudo]{
		display:none;
	}
	&[data-ecrm-artservtypecd="1"] .opc-form-field[data-ecrm-artservtypecd~="1"],
	&[data-ecrm-artservtypecd="2"] .opc-form-field[data-ecrm-artservtypecd~="2"],
	&[data-ecrm-artservtypecd="3"] .opc-form-field[data-ecrm-artservtypecd~="3"],
	&[data-ecrm-artservtypecd="4"] .opc-form-field[data-ecrm-artservtypecd~="4"],
	&[data-ecrm-artservtypecd="5"] .opc-form-field[data-ecrm-artservtypecd~="5"],
	&[data-ecrm-artservtypecd="6"] .opc-form-field[data-ecrm-artservtypecd~="6"],
	&[data-ecrm-artservtypecd="7"] .opc-form-field[data-ecrm-artservtypecd~="7"],
	&[data-ecrm-artservtypecd="8"] .opc-form-field[data-ecrm-artservtypecd~="8"],
	&[data-ecrm-artispseudo="1"] .opc-form-field[data-ecrm-artispseudo="1"]{
		display:block;
	}
	&[data-ecrm-artservtypecd="1"] div.opc-row[data-ecrm-artservtypecd~="1"],
	&[data-ecrm-artservtypecd="2"] div.opc-row[data-ecrm-artservtypecd~="2"],
	&[data-ecrm-artservtypecd="3"] div.opc-row[data-ecrm-artservtypecd~="3"],
	&[data-ecrm-artservtypecd="4"] div.opc-row[data-ecrm-artservtypecd~="4"],
	&[data-ecrm-artservtypecd="5"] div.opc-row[data-ecrm-artservtypecd~="5"],
	&[data-ecrm-artservtypecd="6"] div.opc-row[data-ecrm-artservtypecd~="6"],
	&[data-ecrm-artservtypecd="7"] div.opc-row[data-ecrm-artservtypecd~="7"],
	&[data-ecrm-artservtypecd="8"] div.opc-row[data-ecrm-artservtypecd~="8"]{
		display:flex;
	}
}
