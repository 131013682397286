/* 	CustArtCond-0010.oc
*/
.custartcond-0010.opc-item-list .list-item-column {
	&:nth-child(1),
	&:nth-child(5){
		flex:2;
	}
	&:nth-last-child(1),
	&:nth-last-child(2){
		text-align: right;
	}
}