/* 	
	FullCalendar Overwrite
*/

.fullcalendar-flyout-container {
    min-height: 450px;
	height: calc(100vh - 400px);
}

.fullcalendar-full-container {
	height:calc(100vh - 375px);
	min-height:500px;
}

.fc .fc-more-popover,.fc .fc-popover{
	z-index:8;
}

[data-op-fullcalendar]{
	.fc-unthemed .fc-content,
	.fc-unthemed .fc-divider,
	.fc-unthemed .fc-list-heading td,
	.fc-unthemed .fc-list-view,
	.fc-unthemed .fc-popover,
	.fc-unthemed .fc-row,
	.fc-unthemed tbody,
	.fc-unthemed td,
	.fc-unthemed th,
	.fc-unthemed thead {
		border-color: #aaaaaa;
	}

	table tbody tr {
		border-bottom:0 none transparent;
	}


	.fc-v-event,.fc-h-event{
		background-color:#dddddd;
		border-color:#ccc;
		.fc-event-title-container{
			font-size:1em;
			.fc-event-title::before{
				font-size: 1.2em;
			}
		}
		.fc-event-main{
			color:#000;
		}
		&.activityType9{
			.fc-event-title::before{
				color:#A15F6A;
			}
		}
		&.activityType8{
			.fc-event-title::before{
				color:#5FA19B;
			}
		}
		&.activityType7{
			background-color:rgb(170, 170, 170);
			.fc-event-title::before{
				color:#484838;
			}
		}
		&.activityType6{
			.fc-event-title::before{
				color:#D89273;
			}
		}
		&.activityType2{
			.fc-event-title::before{
				color:#D8D573;
			}
		}
		&.activityType1{
			.fc-event-title::before{
				color:#669ED5;
			}
		}
	}
	.fc-daygrid-day.fc-day-today,.fc-timegrid-col.fc-day-today{
		background-color:#f8f8f8;
	}

	.fc-button:not(:disabled) {
		background: transparent;
        border-color: $_opc-button-primary-bg;
        color: $_opc-button-primary-bg;
        border-radius: $_opc-button-primary-border-radius;
        &:hover, &:focus, &:active {
            color: $_opc-button-primary-bg-hover;
			border-color: $_opc-button-primary-bg-hover;
			background: transparent;
			&:disabled {
				border-color: $_opc-button-primary-bg;
				color: $_opc-button-primary-bg;
			}
        }
	}

	.tooltip{
		width: 40rem;
		.tooltip-inner{
			text-align:left;
			.date{
				font-style:italic;
			}

			.title{
				font-weight:bold;
			}
		}
	}
}

.fc-view-container table tbody tr{
	border-bottom:none;
}

.fc-bgevent-skeleton {
	pointer-events: none;
}
.fc-bgevent-skeleton .fc-bgevent {
	pointer-events: auto;
}
.fc-content-skeleton {
	pointer-events: none;
}
.fc-content-skeleton .fc-event {
	pointer-events: auto;
}