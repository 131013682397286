
/* *****************************************

SVG Inline Functions

***************************************** */

// Replace letters
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// Encode symbols
@function url-encode($string, $color) {
    $map: (
        "%": "%25",
        "<": "%3C",
        ">": "%3E",
        //" ": "%20",
        "!": "%21",
        "*": "%2A",
        "'": "%27",
        '"': "%22",
        "(": "%28",
        ")": "%29",
        ";": "%3B",
        ":": "%3A",
        "@": "%40",
        "&": "%26",
        "=": "%3D",
        "+": "%2B",
        "$": "%24",
        ",": "%2C",
        "/": "%2F",
        "?": "%3F",
        "#": "%23",
        "[": "%5B",
        "]": "%5D"
    );

    $new: str-replace($string, 'height="32"', 'height="32" fill="#{$color}" style="color:#{$color}"');
    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }

    @return $new;
}

// Format the SVG as a URL
@function inline-svg($string, $color) {
    @return url('data:image/svg+xml,#{url-encode($string, $color)}');
}

/* Ergaenzungen Autocomplete */

.opc-autocomplete .form-field-list .select-list .list-item-description {
    font-size: 0.9em;
}

.opc-autocomplete .form-field-list .select-list li {
    border-bottom: 1px solid $color-greylight;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    img.list-item-image {
        margin-left: auto;
        padding-left: 1rem;
        padding-right: 0;
    }
}

$op-icon-search-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path fill="currentColor" d="M19 3c-5.512 0-10 4.488-10 10 0 2.395 0.84 4.59 2.25 6.313l-7.969 7.969 1.438 1.438 7.969-7.969c1.723 1.41 3.918 2.25 6.313 2.25 5.512 0 10-4.488 10-10s-4.488-10-10-10zM19 5c4.43 0 8 3.57 8 8s-3.57 8-8 8c-4.43 0-8-3.57-8-8s3.57-8 8-8z"></path></svg>';
$_opc-search-icon: inline-svg($op-icon-search-svg, "999999");

.opc-autocomplete {
	position: relative;

	&::after{
		color: $color-grey;
		font-family: 'Font Awesome 5 pro';
		position: absolute;
		right: 10px;
		top: 10px;
		display: block;
		content: "\f002";
		pointer-events:none;
	}

	.remove-icon::after {
		color: $color-grey;
		font-family: 'Font Awesome 5 pro';
		position: absolute;
		right: 1.6em;
		top: 0px;
		padding: 0.5em 0.6em;
		font-size: 1.2em;
		content: "\f00d";
		-webkit-animation: none;
		-moz-animation: none;
		-ms-animation: none;
		-o-animation: none;
		animation: none;
		cursor:pointer
	}
	.remove-icon.hide{
		display:none;
	}

	input[type=text][disabled] ~ .remove-icon{
		display:none;
	}
	input[type=text][readonly] ~ .remove-icon{
		display:none;
	}
	

	&.is-inactive:not(.is-loading)::after{
		display:none;
	}

	.opc-input[data-op-type="autocomplete"] {
		background: #fff;
	}
	&.is-loading {
		&::after{
			content: "\f1ce";
			-webkit-animation: fa-spin 2s linear infinite;
			-moz-animation: fa-spin 2s linear infinite;
			-ms-animation: fa-spin 2s linear infinite;
			-o-animation: fa-spin 2s linear infinite;
			animation: fa-spin 2s linear infinite;
		}
		.opc-input[data-op-type="autocomplete"], .opc-input[data-op-type="autocomplete"]:read-only {
			background: #f8f8f8;
			position:relative;
		}
	}

}

fieldset[readonly] input[type=text] ~ .remove-icon{
	display:none;
}
fieldset[disabled] input[type=text] ~ .remove-icon{
	display:none;
}