.opc-button {
    transition: none;
    svg, i.fa {
        margin-right: 0;
    }
}

.opc-button-wrapper {
    .opc-button {
        margin-right: 1rem;
    }
    &[data-op-align="right"] {
        .opc-button {
            margin-right: 0;
        }
    }

	&:only-child{
		margin-bottom:0;
	}
}

.opc-button[data-op-size="large"][data-op-type="link"] {
    font-size: 1.8rem;
    height: auto;
	padding: 0;
	color: inherit;
    &:hover {
        color: $brand-color-1;
    }
}

.opc-button[data-op-size="tiny"][data-op-type="link"] {
    font-size: $_opc-button-font-size-m;
    svg, i.fa {
        font-size: $_opc-button-font-size-s;
    }
     :nth-child(2) {
        margin-left: 0.5rem;
    }
    &:hover {
        background-color: rgba(#000, 0.05);
    }
}

.opc-button {
    transform: scale(1);
    transition: 0.1s all linear;
    &.loading {
        position: relative;
        padding-right: 34px;
        &:after {
            font-family: 'Font Awesome 5 pro';
            position: absolute;
            right: 10px;
            top: calc(50% - 8px);
            content: "\f1ce";
            -webkit-animation: fa-spin 2s linear infinite;
            -moz-animation: fa-spin 2s linear infinite;
            -ms-animation: fa-spin 2s linear infinite;
            -o-animation: fa-spin 2s linear infinite;
            animation: fa-spin 2s linear infinite;
        }
        &[data-op-size=tiny] {
            padding-right: 30px;
            &:after {
                top: calc(50% - 7px);
            }
        }
        &[data-op-size=large] {
            padding-right: 43px;
            &:after {
                right: 15px;
            }
        }
        &[data-op-size=huge] {
            padding-right: 52px;
            &:after {
                right: 18px;
            }
        }
	}
	
	&.opc-tag{
		cursor:default;
		pointer-events:none;
		
	}

	&[data-op-style="secondary"] {

        &.is-active {
            color: $_opc-button-secondary-color-hover;
			background: $_opc-button-secondary-bg-hover;
        }
    }
}

.opc-button[data-op-badge]{
	cursor:default;
	pointer-events: none;
}

@include mq($max-width: map-get($breakpoints, m)) {
	.opc-button-wrapper{
		flex-wrap: wrap;
		.opc-button{
			flex-basis: 100%;
			width: 100%;
			margin-top:0.6em;
			margin-right:0;
		}
	}
}