html.page-login{
	overflow:auto;
	body{
		height:auto;
		//background-color: $theme-color-1;
		background-image: radial-gradient(98.62% 95.12% at 50% 50%,lighten($theme-color-1,15%) 0,darken($theme-color-1,10%) 100%);
		min-height:100%;
	}
}

.splashscreen {
	//background-image: url($tk-path + "/img/splashscreen_1_20.png");
    //background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

	padding-bottom: 2em;
	@include mq($min-width: map-get($breakpoints, m)) {
		height: 100vh;
		padding-bottom: 5em;
	}
	@include mq($max-width: map-get($breakpoints, m)) {
		align-items: flex-start;
		background-image:none;
		padding-top: 2em;
	}
	*{font-size: 1.5rem;}

    .login-form {
		padding: 1em;
   		width: 40rem;
		.panel-head{
			display:block;
			padding: 4rem 0 0 0;
			margin: 0 3rem;
			border-bottom: 0 none transparent;

			+.panel-body{
				padding-top:3rem;
			}
		}
		.panel-body{
			background:#FFF;
			padding:3rem;
		}
		.customer-logo {
			img {
				width: auto;
				max-height: 60px;
				max-width: 100%;
			}
			text-align:center;
		}
		.client-name {
			padding: 0.75rem 0 0 0;
			text-align:center;
        }
        p {
            margin-bottom: 1em;
        }
        .login-logo {
            margin: 0 auto;
			@include mq($max-width: map-get($breakpoints, m)) {
				padding:0 3rem;
			}
            display: block;
            margin-bottom: 2rem;
            width: 100%;
            img {
                max-width: 100%;
                width: 100%;
            }
		}
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus textarea:-webkit-autofill,
		textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
		select:-webkit-autofill,
		select:-webkit-autofill:hover,
		select:-webkit-autofill:focus {
		  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
		}
        .opc-form-field {
            margin-bottom: 1.5rem;
            .form-field-element {
                input[type="text"],
                input[type="password"] {
                    //background: rgba(#dbdbb5, 0.8);
                    //border-color: transparent;
                    &:focus {
                   //     background: rgba(#dbdbb5, 1);
                    }
                }
            }
        }
        .form-footer {
            padding:0 0 0 0;
            text-align: right;
            a {
                
            }
            p {
				padding: 1rem 0 0 0;
				margin:0;
            }
        }
        .opc-button {
            width: 100%;
		}

		.pwd-strength{
			background: rgba( #fff, 0.2); 

			margin-top: 0.2rem;
			padding-top:1rem;
			padding-bottom:0;

			ul li:last-child{
				margin-bottom:0;
			}
		}

		.form-field-error{
			background: $error-color;
			color: #fff;
			padding:1rem;
			margin-top:1rem;
		}
    }
}


.page-bg {
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: url($tk-path + "/img/splashscreen_1_40.png") no-repeat center center fixed;
	background-size: cover;
	z-index: -1;
	
	-webkit-animation-name: fadeIn;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-timing-function: ease-in-out;
	-webkit-animation-duration: 0.5s;
	-webkit-animation-fill-mode:forwards;
	
	animation-name: fadeIn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	animation-duration: 0.5s;
	animation-fill-mode:forwards;
}
@include mq($max-width: map-get($breakpoints, m)) {
	.page-bg {
		background: none;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}