.popper,
.tooltip {
    background: $tooltip-bg-color;
	color: $tooltip-font-color;
	box-shadow: 0 5px 10px rgba(0,0,0,0.2);
	text-align: left;
    width: auto;
	border:1px solid rgba(0,0,0,0.2);
	max-width:300px;
}

.tooltip .tooltip-arrow {
    border-color: rgba(0,0,0,0.2);
}