/*
	Addr-0020.oc
	Felder Anhand Adresstyp ein/ausblenden
*/

form#OCFromAddr[data-ecrm-addrtype="0"] .opc-form-field[data-ecrm-addrtype="1"],
form#OCFromAddr[data-ecrm-addrtype="1"] .opc-form-field[data-ecrm-addrtype="0"],
form#OCFormAddrNew[data-ecrm-addrtype="0"] .opc-form-field[data-ecrm-addrtype="1"],
form#OCFormAddrNew[data-ecrm-addrtype="1"] .opc-form-field[data-ecrm-addrtype="0"] {
    display: none;
}

.addr-0110 .flexbox {
    display: flex;
    justify-content: space-between;
	flex-wrap:wrap-reverse;
    @include mq($max-width: map-get($breakpoints, s)) {
        flex-direction: column;
        justify-content: flex-start;
    }
    .flexitem {
        flex: 0 1 auto;
    }
    span.image {
        img {
            max-width: 220px;
            max-height: 220px;
        }
    }
}

// Passive Adressen: !
.icon-addr-ispassive{
	color:$error-color;
}
// Adressen - Keine Rechte fuer angemeldeten User: !
.icon-addr-norights{
	color:$error-color;
}

// Adresszusammenfassung Dublettencheck
.opc-item-list.addr-duplicate-check-newaddr{
	max-width:60rem;
	@include mq($max-width: map-get($breakpoints, m)) {
		max-width:auto;
	}
	.list-item,.list-item:hover{
		padding:0;
		&:nth-child(1){
			border-top: 1px solid #efefef;
		}
		.list-item-column:nth-child(1){
			@include mq($max-width: map-get($breakpoints, m)) {
				display:none;
			}
			font-weight:bold;
		}
	}
}