// Tag-List
.tag-list {
    &[data-op-size="tiny"] {
        .tag {
            padding: 0.5rem 0.5rem 0.5rem 0.5rem;
            font-size: 1.1rem;
        }
    }
    .tag {
        padding: 1rem 2rem 1rem 2rem;
        display: inline-block;
        margin-right: 0.2rem;
        margin-bottom: 0;
        background: #efefef;
        color: #555;
        border-radius: 5px;
        font-size: 1.2rem;
        position: relative;
        .tag-type {
            opacity: 0.8;
            margin-right: 0.1rem;
        }
        .tag-value {
            font-weight: bold;
            display: inline-flex;
			align-items: baseline;
			text-overflow: ellipsis;
			max-width: 150px;
			white-space:nowrap;
			overflow:hidden;
        }
        .tag-remove {
            margin-left: 1rem;
            opacity: 0.6;
            font-weight: bold;
            padding: 0 1.5rem 0 1rem;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            text-indent: -9999px;
            overflow: hidden;
            background-image: inline-svg($op-icon-cross-svg, '#fff');
            background-size: 1.6rem;
            background-repeat: no-repeat;
            background-position: calc(100% - 1rem) center;
            &:hover {
                opacity: 1;
            }
            &::before {
                content: 'x';
            }
        }
    }
}