/*###############################################

@Title: Footer
@Description:


###############################################*/

// namespace vars
$_footer-bg: 					#494949;	// background of footer
$_footer-inner-bottom-bg:		$_footer-bg;	// background of bottom part of the footer
$_footer-padding:				4rem;	// padding of the footer
$_footer-inner-bottom-padding: 	6rem;	// padding of the bottom part of the footer

$_footer-color:					rgba(#fff, 0.6);
$_footer-color-hover:			rgba(#fff, 0.8);


// footer layout styles

.footer-wrapper {
	@extend %mw;
	margin-top: auto;
	background: $_footer-bg;
	padding-top: $_footer-padding;
	overflow: hidden;
	
	
	.footer-inner {
		@extend %mw-inner;
		display: flex; 
		flex-wrap: wrap;
		padding-bottom: $_footer-padding;
		
		.opc-row {
			width: calc(100% + (#{$_opc-grid-gutter-width} * 2));
		}
	}

	.footer-inner-payment {
		@extend %mw-inner;
		display: flex; 
		flex-wrap: wrap;
		padding-top: $_footer-padding / 2;
		padding-bottom: $_footer-padding / 2;
		border-top: 1px solid rgba(#fff, 0.2);
		justify-content: center;
		width: 100%;

		@include mq($min-width: map-get($breakpoints, m)) {
			justify-content: flex-start;
		}

		img {
			margin-right: 1rem;
		}
	}

	.footer-col-categories {
		display: none;
		

		@include mq($min-width: map-get($breakpoints, m)) {
			display: block;
		}
	}
	
	.footer-inner-bottom {
		@extend %mw-inner;
		background: $_footer-inner-bottom-bg;
		position: relative;
		border-top: 1px solid rgba(#fff, 0.2);
		width: 100%;
		
		padding-top: $_footer-inner-bottom-padding / 2;
		padding-bottom: $_footer-inner-bottom-padding;
 


		@include mq($min-width: map-get($breakpoints, m)) {
			
		}
				
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 2000px;
			top: 0;
			bottom: 0;
			background: $_footer-inner-bottom-bg;
		}
		
		&::before {
			right: 100%;
		}
		&::after {
			left: 100%;
		}
	}
	
	
	.footer-col {
		width: span(12);
		max-width: span(12);
		padding-right: $_opc-grid-gutter-width;
		padding-left: $_opc-grid-gutter-width;
		padding-bottom: $_opc-grid-gutter-width * 2;
		text-align: center;
		color: $_footer-color;

		&.footer-col-services {
			//text-align: left;
		}
				
		h2 {
			font-size: 1.8rem;
			margin: 0 0 1rem;
			padding: 0;
			color: #fff;
		}
		
		@include mq($min-width: map-get($breakpoints, m)) {
			width: span(3);
			max-width: span(3);
			text-align: left;
		}
		@include mq($min-width: map-get($breakpoints, xl)) {
			padding-bottom: $_header-padding;
			width: span(3);
			max-width: span(3);
		}

		.opc-static-content {
			@extend .text;
		}
		
		p {
			color: $_footer-color;
		}
        label {
            color: $_footer-color;
		}
		
	}

	// newsletter in footer
	.opc-newsletter {
		max-width: 40rem;
		margin: 0 auto;
		label {
			text-align: left;
		}
		.opc-button {
			width: 100%;
			@include mq($min-width: map-get($breakpoints, m)) {
				width: auto;
			}
		}
	}

	
	// nav im footer
	.opc-navigation {

		&[data-op-navtype="simple"] {
			a {
				color: $_footer-color;
				&:hover {
					color: $_footer-color-hover;
				}
				
				&::before {
					margin-right: 0.5rem;
					content: '';
					width: 1em;
					height: 1em;
					display: inline-block;
									
					background-image: inline-svg($op-icon-angle-right-svg, '#fff');
									
					//background-size: 10px;
					background-position: center;
					background-size: 100%;
					background-repeat: no-repeat;
					opacity: 0.6;
	
				}
				
				&:hover {
					&::before {
						opacity: 1;
					}
				   
				}
			}
			// nav level 1
			.navigation-level-1 {
				>li {
					>a {
						padding: 0.5rem 0;
					}
					&:hover {
						// style a tag of hovered element
						>a {}
						// show nav level 2 on hover if available
						>.navigation-level-2 {}
					}
				}
			}
			// nav level 2
			.navigation-level-2 {
				display:none; 
				>li {
					>a {
						white-space: nowrap;
					}
					&:hover {
						>a {
							background: rgba(#000, 0.1);
						}
					}
				}
			}
			.navigation-level-3 {
				//display: none;
			}
		}
		
		
		
	}

	
	
	.footer-bottom-nav {
		float: left;
		width: 100%;
		text-align: center;
		margin-bottom: 2rem;

		@include mq($min-width: map-get($breakpoints, m)) {
			float: left;
			width: auto;
			text-align: left;
			margin-bottom: 0;
		}
		
		
		a {
			color: $_footer-color;
			margin-right: 1rem;
			
			&:hover {
				color: $_footer-color-hover;
			}
		}
	}
	.footer-copy {
		width: 100%;
		text-align: center;
		color: $_footer-color;
		@include mq($min-width: map-get($breakpoints, m)) {
			float: right;
			width: auto;
			text-align: right;
		}
	}
	
	
	
}