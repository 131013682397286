/*###############################################

@Title: Icons
@Description:
This file contains the SVG icon styles and raw icons

###############################################*/

svg, i.fa {
    pointer-events: auto;
}