/*
	Verkaufsdokumente DocStateCd Icons
*/
[data-op-item-marker-value^="Opportunity-Opportunity_OpportLevelNo"],[data-op-item-marker-value^="OpportunityUser-Opportunity_OpportLevelNo"]{
    &:before {
		content: "\f0c8";
		font-weight: 900;
    }
}

/* 	Opportunity-0010.oc
*/
.opportunity-0010.opc-item-list .list-item-column {
    &:nth-child(2){
		flex: 3;
	}
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(8) {
        flex: 2;
    }
    &:nth-child(9) {
        text-align: right;
    }
}

/* 	Opportunity-0015.oc
*/
.opportunity-0015.opc-item-list .list-item-column {
	&:nth-child(2),
	&:nth-child(3){
		flex: 3;
	}
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(9) {
        flex: 2;
    }
    &:nth-child(10) {
        text-align: right;
    }
}

// Filter ausblenden (Nur als Init verwendet)
.opc-filter-set-opportunity .filter-set-list-item{
	&:nth-child(8),&:nth-child(9),&:nth-child(10),&:nth-child(11){
		display:none;
	}
}

/* 	Opportunity-0040.oc
*/
.opportunity-0040.opc-item-list .list-item-column {
    flex: 1;
    &:nth-child(4) {
        flex: 3;
    }
}


/* 	Opportunity-0045.oc
*/
.opportunity-0045.opc-item-list .list-item-column {
    flex: 2;
    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(7),
    &:nth-child(8) {
        flex: 1;
    }
    &:nth-child(7) {
        text-align: right;
    }
}

/* 	Opportunity-0100.oc
*/

.opportunity-0100.opc-item-list .list-item-column {
    &:nth-child(5) {
        text-align: right;
    }
}