
/*Dokumente*/
.Dmas-0010.opc-item-list {
	.item-list-body>.list-item{
		border-top:1px solid #ddd;
	}
	.list-item-column {
		flex:2;
		&:nth-child(1){
			flex:1;
		}
		&:nth-child(2){
			flex:4;
		}
	}

}

/*Dateien*/
.DmasFiles-0010.opc-item-list {
	.item-list-body>.list-item{
		border-top:inherit;
	}
	.list-item{
		padding: 0.3rem 0.5rem;

		.list-item-column {

			&:nth-child(2){
				div{
					padding-left:2rem;
				}
				svg{
					font-size:1.4em;
					color:$theme-color-1;
					margin-right:0.5em;
				}
			}
		}
	}
}

.DmasFiles-0030.opc-item-list{
		.list-item-column {
			&:nth-child(1){
				flex:3;
				svg{
					font-size:1.4em;
					color:$theme-color-1;
					margin-right:0.5em;
				}
			}
		}
	
}

h2.dmas-detail{
	span{
		margin: 0 1.5em;
		font-size:1.5rem;
	}
}