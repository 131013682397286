.image-gallery{

	.image-gallery-big,.image-gallery-small{
		border:1px solid #efefef;
		margin-bottom:10px;
	}
	.image-gallery-big{
		width:100%;
		height:30vh;
		cursor:pointer;
		text-align:center;

		img{
			max-width: 100%;
			max-height: 100%;
		}
	}
	.image-gallery-thumbnails{
		display: flex;
		flex-wrap: wrap;
		align-content: space-between;
		justify-content: flex-start;

		.image-gallery-small{
			width:calc(25% - 10px);
			margin-right:10px;
			margin-bottom:10px;
			height:10rem;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			&:nth-child(4n){
				margin-right:0;
				width:calc(25%);
			}

			cursor:pointer;

			&.active{
				border:1px solid $brand-color-1;
				cursor:default;
			}
		}
	}

}


//FullScreen
.image-gallery.image-gallery-fullscreen{
	height:100%;

	.image-gallery-big{
		height:calc(100% - 14rem - 30px);
		position:relative;
		border:1px solid #fff;
		padding: 0 6rem;
		min-height: 18rem;
		.img{
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			margin: 0 auto;
			width: 100%;
			height: 100%;
		}
		.next-image,.prev-image{
			position:absolute;
			top:calc(50% - 2.5rem);
			width: 5rem;
			height:5rem;
			color:rgba(0,0,0,0.2);
			cursor:pointer;
			&:hover{
				color:$brand-color-1;
				opacity:0.5;
			}
		}
		.next-image{
			right:0rem;
		}
		.prev-image{
			left:0rem;
		}
	}
	.image-gallery-thumbnails{
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: normal;
		align-content: normal;
		.image-gallery-small{
			width:18rem;
			height:14rem;
			margin-right:10px;
			margin-bottom:20px;
			min-width:12rem;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			transition: border-color 0.2s linear;
			&:nth-child(4n){
				margin-right:10px;
				width:18rem;
				height:14rem;
				min-width:13rem;
			}
		}
	}

	&.only-one-image{
		.image-gallery-big{
			height:calc(100% - 10px);
		}
		.image-gallery-thumbnails{
			display:none;
		}
	}


}


@include mq($max-width: map-get($breakpoints, s)) {
	.image-gallery.image-gallery-fullscreen{
		.image-gallery-big{
			height:calc(100% - 10rem - 30px);
			padding: 0;
		}
		.image-gallery-thumbnails{
			.image-gallery-small,.image-gallery-small:nth-child(4n){
				width:14rem;
				height:10rem;
			}
		}
	}
}