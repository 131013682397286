/*
	MisSal
*/
.opportunity_statistic_form {
    
    .opc-form-field {
        margin-bottom: 1em;
    }
    .opc-row .opc-col {
        margin-bottom: 0px;
	}
}

#opportunity-funnel{
	max-width:900px;
}