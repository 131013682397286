/*###############################################

@Title: Topbar
@Description:


###############################################*/


// namespace vars

$_topbar-bg: 				#fff;
$_topbar-padding: 			1rem;
$_topbar-group-divider-color:	#eee;


#topbar-login-form {
	display: none;
  }  

// topbar layout styles

.topbar-wrapper {
	@extend %mw;
	
	background: $_topbar-bg;
	padding-top: $_topbar-padding;
	padding: 0;
      
    display: none;
    
    @include mq($min-width: map-get($breakpoints, m)) {
        display: block;
    }
	 
	
	.topbar-inner {
		@extend %mw-inner;
		//text-align: right;
		font-size: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
		height: 4rem;
	}
	
	.topbar-group {
		display: inline-block;
		text-align: left;
		padding-left: 1rem;
		margin-left: 1rem;
		position: relative;
		line-height: 1.6;
		font-size: 1.4rem;
		
		&:first-child {
			&::before {
				display: none;
			} 
		}
		
		&::before {
			content: '';
			display: block;
			width: 1px;
			background: $_topbar-group-divider-color;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
		}
		
		select {
			font-weight: bold;
			border: none;
		}
		
		a {
			//color: inherit;
			text-decoration: none;
			color: #000; 
			&:hover {
				color: lighten(#000, 40%);
			}
		}
		.count {
			&::before {
				content: '(';
			}
			&::after {
				content: ')';
			}
		}
	}
	.admin-panel {
		
	}
}