/*###############################################

@Title: Tabs
@Description:
This file contains the tabs styles

###############################################*/

$_opc-tab-list-bg: #fff !default;
$_opc-tab-bg: transparent !default;
$_opc-tab-bg-hover: #eee !default;
$_opc-tab-bg-active: #ccc !default;
$_opc-tab-border-color: #ccc !default;
$_opc-tab-border-thickness: 1px !default;
$_opc-tab-accordion-bg: transparent !default;
$_opc-tab-accordion-bg-hover: transparent !default;
$_opc-tab-accordion-bg-active: transparent !default;
$_opc-tab-color: #999 !default;
$_opc-tab-color-hover: #666 !default;
$_opc-tab-color-active: #000 !default;
// tab config
$_opc-tab-border-on-all-tabs: "false" !default;
$_opc-tab-fullwidth-tablist: "true" !default;
$_opc-tab-list-padding: 0 !default;
$_opc-tab-padding: 2rem 4rem !default;
$_opc-tab-head-padding: 2rem !default;
$_opc-tab-body-padding: 2rem !default;
$_opc-tab-head-arrow-right-padding: 2rem !default;


.opc-tabs {
    // list of all tab labels
    .tab-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        flex-flow: row wrap;
        border-bottom: $_opc-tab-border-thickness solid $_opc-tab-border-color;
        position: relative;
        padding: $_opc-tab-list-padding;
		background: $_opc-tab-list-bg; 
		
		// fullwidth tab
        @if $_opc-tab-fullwidth-tablist=="true" {
            &::before,
            &::after {
                content: '';
                height: 100%;
                width: 100%;
                display: block;
                background: $_opc-tab-list-bg;
                position: absolute;
                right: 100%;
                top: 0;
                bottom: 0;
                border-bottom: $_opc-tab-border-thickness solid $_opc-tab-border-color;
            }
            &::after {
                left: 100%;
                right: auto;
            }
		} 
		// tab item
        li,
        .tab-list-item {
            background: $_opc-tab-bg;
            position: relative;
            border: $_opc-tab-border-thickness solid $_opc-tab-border-color;
            border-color: transparent;
            border-bottom: none;
            color: $_opc-tab-color;
            @if $_opc-tab-border-on-all-tabs=="true" {
                margin-left: -$_opc-tab-border-thickness;
                border-color: $_opc-tab-border-color;
            }
            else {
                border-color: $_opc-tab-bg;
            }
            &:hover {
                background: $_opc-tab-bg-hover;
                color: $_opc-tab-color-hover;
            }
            &:first-child {
                margin-left: 0;
            }
            &.is-active {
                z-index: 2;
                background: $_opc-tab-bg-active;
                color: $_opc-tab-color-active;
                border-color: $_opc-tab-border-color;
                border-bottom: none;
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    height: $_opc-tab-border-thickness;
                    left: 0;
                    right: 0;
                    bottom: -$_opc-tab-border-thickness;
                    background: $_opc-tab-bg-active;
                }
            }
            a {
                width: 100%;
                display: block;
                padding: $_opc-tab-padding;
                color: inherit;
                text-decoration: none;
            }
        }
    }
    .tab-content-wrapper {
        margin-bottom: 2rem;
        .tab-content-inner {
            display: none;
            .tab-head {
                padding: $_opc-tab-head-padding;
                background: $_opc-tab-accordion-bg;
                position: relative;
                font-weight: bold;
                &:hover {
                    background: $_opc-tab-accordion-bg-hover;
                }
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: $_opc-tab-head-arrow-right-padding;
                    top: 50%;
                    transform: translateY(-50%) rotate(0);
                    background-image: inline-svg($op-icon-angle-down-svg, '#999');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 1.2em;
                    height: 1.2em;
                }
            }
            .tab-body {
                padding-top: $_opc-tab-body-padding;
                padding-bottom: $_opc-tab-body-padding;
            }
            &.is-active {
                display: block;
                .tab-head {
                    background: $_opc-tab-accordion-bg-active;
                    &::after {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }
        }
    } // specific styles for tab mode
    &[data-op-type="tabs"] {
        .tab-head {
            display: none;
        }
    } // specific styles for accordion mode
    &[data-op-type="accordion"] {
        .tab-list {
            display: none;
        }
        .tab-content-inner {
            display: block;
            border: $_opc-tab-border-thickness solid $_opc-tab-border-color;
            margin-top: -$_opc-tab-border-thickness;
            .tab-body {
                display: none;
                padding: $_opc-tab-body-padding;
            }
            &.is-active {
                .tab-body {
                    display: block;
                }
            }
        }
    }
}