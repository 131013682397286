/* 	Fiacc-0010.oc
*/
.fiacc-0010-bonitaet.opc-item-list .list-item-column:first-child {
	flex: 2;
}
.fiacc-0010-bonitaet.opc-item-list .list-item-column:nth-child(2) {
	text-align: right;
}

.fiacc-0010-bonitaet.opc-item-list .list-item-column:nth-child(3) {
	text-align: right;

	&[data-op-type="error"] {
		font-weight:bold;
        color: $error-color;
    }
}

.fiacc-0010.opc-item-list .list-item-column:nth-child(4) {
	text-align: right;
}
.fiacc-0010.opc-item-list .list-item-column:nth-child(5) {
	text-align: right;
	padding-right: 25px;
}
.fiacc-0010.opc-item-list .list-item-column:nth-child(10) {
	text-align: right;
} 