/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
@media
only screen 
and (max-width: 760px), (min-device-width: 768px) 
and (max-device-width: 1024px)  {

	table.responsive{
		display: block;
		padding:1rem 0.2rem !important;
		
		/* Force table to not be like tables anymore */
		thead, tbody, th, td, tr {
			display: block;
			padding:1rem 0.2rem !important;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		tr {
		margin: 0 0 0 0;
		}
		
		tr:nth-child(odd) {
		background: #f8f8f8;
		}
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-top: none;
			position: relative;
			padding-left: 50% !important;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding: 1rem 0.2rem !important;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		td:before { content:attr(data-label); }
	}

}

table.responsive{
	th,td{
		text-overflow: ellipsis;
			overflow: hidden;
	}
}