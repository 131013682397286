/* 	
Activity-Statistic.oc
Spaltenbreite Liste
Mobile nur Totale anzeigen
*/

.activity-statistic-0010 .opc-item-list {
    .list-item-column:nth-last-child(-n+3):not(:first-child()) {
        @include mq($min-width: map-get($breakpoints, xl)) {
            display: none;
        }
    }
    .list-item-column:not(:nth-last-child(-n+3)):not(:first-child()) {
        @include mq($max-width: map-get($breakpoints, xl)) {
            display: none;
        }
    }
}

div[class*='activity-statistic'] .opc-item-list .list-item-column button.opc-button {
    cursor: default;
}

div[class*='activity-statistic'] .opc-item-list .list-item-column button.opc-button.is-inactive,
div[class*='activity-statistic'] .opc-item-list .list-item-column a.opc-button.is-inactive {
    opacity: 0.1;
    filter: grayscale(100%);
}

div[class*='activity-statistic'] button.opc-button[data-op-style="primary"], 
div[class*='activity-statistic'] a.opc-button[data-op-style="primary"] {
    background: $success-color;
	color: rgba(#fff, 0.8);
}

.activity-statistic-0010 .opc-item-list .list-item-column {
    flex: 1;
    min-width: 60px;
}

.activity-statistic-0010 .list-item-column:nth-child(1) {
    flex: 2;
    white-space: nowrap;
}

.activity-statistic-0010 .list-item-column:nth-child(14) {
    white-space: nowrap;
    min-width: 86px;
}


/* Tooltop NOBR */

.activity-statistic-0010 .list-item-column .tooltip {
    width: auto;
}

.activity-statistic-0100 .list-item-column:nth-child(1) {
    flex: 3;
}


/* Legende */

.activity-statistic-legend span {
    display: inline-block;
    margin-right: 0.6em;
    .opc-button {
        cursor: default;
        pointer-events: none;
    }
}
