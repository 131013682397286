.opc-item-list {
	
    .list-item,
    .item-list-head {
        display: block;
        color: #000;
        padding: 0.5rem;
        .list-item-column {
			min-width:0;
            display: flex;
			padding: 0.4rem 0;
			margin-bottom: 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
			width: 100%;
			align-items:flex-start;
			flex-wrap : wrap;
			gap: 0.8rem;
			//Umbruch bei Spalten mit Bildern verhindern
			&.nowrap{
				white-space: nowrap;
			}

			span.image-info{
				padding:5px;
				padding-left:0px;
				span img{
					max-height: 25px;
					max-width: 25px;
					margin-right:5px;
				}
			}

			svg{
				margin-right: 0.2em;
			}
            .fa-icon {
                display: inline-block;
                margin-right: 0.4em;
                &:before {
                    font-family: 'Font Awesome 5 Pro';
                    font-size: 1.2em;
                }
			}

			.image{
				text-align:center;
				display:block;
				width:100%;

				img {
					max-width: 95%;
					max-height: 40px;
					vertical-align: middle;
				}
			}
            &[data-column-width="2"] {
                flex: 2;
            }
            &[data-column-width="3"] {
                flex: 3;
            }
            &[data-column-width="4"] {
                flex: 4;
            }
            &[data-column-width="5"] {
                flex: 5;
            }
            &[data-column-width="6"] {
                flex: 6;
            }
            &[data-column-width="7"] {
                flex: 7;
            }
            &[data-column-width="8"] {
                flex: 8;
            }
            &[data-column-width="9"] {
                flex: 9;
            }
            &[data-column-width="10"] {
                flex: 10;
			}
			&[data-column-width="11"] {
                flex: 11;
			}
            &[data-column-width="12"] {
                flex: 12;
			}
            &[data-column-width="13"] {
                flex: 13;
			}
            &[data-column-width="14"] {
                flex: 14;
			}
            &[data-column-width="15"] {
                flex: 15;
			}

			@include mq($max-width: map-get($breakpoints, s)) {
				&[data-column-hide~="s"] {
					display:none;
				}
			}
			@include mq($max-width: map-get($breakpoints, m)) {
				padding: 0.1rem 0;
				.image{
					display:inline-block;
					width:auto;
				}
				&:empty{
					display:none;
				}
			}

			@include screen(map-get($breakpoints, s),map-get($breakpoints, m)) {
				&[data-column-hide~="m"] {
					display:none;
				}
			}

			@include screen(map-get($breakpoints, m),map-get($breakpoints, l)) {
				&[data-column-hide~="l"] {
					display:none;
				}
			}

           
            >.opc-button[data-op-type="link"]{
                display: inline-block;
                text-overflow: ellipsis;
                max-width: 100%;
                height: auto;
				margin: -0.5rem 0;
				padding:0;
            }

			// Text abgekuerzt mit Icon
			span.text-short{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				&:before{
					font-family: 'Font Awesome 5 pro';
					content: "\f036";
					margin-right: 0.4rem;
				}
			}
        }
    }
    .item-list-head {
        display: none;
        font-weight: 600;
    }
    .list-item {
		&[data-op-href],&.is-active[data-op-href]{
			cursor:pointer;
		}
		border-bottom: 1px solid #efefef;

        .list-item-column::before {
            content: attr(data-label) '\A' attr(data-label2);
            display: inline-block;
            flex: 0 0 40%;
            font-weight: 600;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
		}
		// Zebra: nur bei Mobile Ansicht
		/*@include mq($max-width: map-get($breakpoints, m)) {
			&:nth-child(odd):not(.is-active) {
				background-color: #f8f8f8;
			}
		}*/
		&:hover {
            padding: 0px;
            padding: 0.5rem;
            background: transparent;
            &:link,
            &[data-op-linkedfield],
			&[data-op-view],
			&[data-op-link] {
                background: #eee;
                cursor: pointer;
            }
		}
		&.is-active {
            background-color: rgb(228, 226, 226);
            //background-color: #eaead3;
            cursor: default;
            button:hover {
                background: #fff;
            }
        }
    }
}

@include mq($min-width: map-get($breakpoints, m)) {
    .opc-item-list {
        .item-list-head,
        .list-item {
            display: flex;
			align-items: center;
            .list-item-column {
				display: block;
                flex: 1;
				margin:0;
                &::before {
                    display: none;
                }
            }
        }
    }
}