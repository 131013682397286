/* 	AddrPool-0010.oc
*/

.addrpool-0010.opc-item-list .list-item-column {
    flex: 3;
}

.addrpool-0010.opc-item-list .list-item-column:nth-child(1),
.addrpool-0010.opc-item-list .list-item-column:nth-child(3),
.addrpool-0010.opc-item-list .list-item-column:nth-child(7),
.addrpool-0010.opc-item-list .list-item-column:nth-child(8) {
    flex: 1;
}

.addrpool-0010.opc-item-list .list-item-column:nth-child(5),
.addrpool-0010.opc-item-list .list-item-column:nth-child(6) {
    flex: 2;
}

.addrpool-0010.opc-item-list .list-item-column:nth-child(8) {
    text-align: right;
}

/* 	AddrPool-0015.oc
*/
.addrpool-0015.opc-item-list .list-item-column {
    flex: 1;
}

.addrpool-0015.opc-item-list .list-item-column:nth-child(2) {
    flex: 5;
}
